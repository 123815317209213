<template>
  <div class="content">
    <div class="top">
      <a-input
          id="sellerExpressNoInputId"
          ref="sellerExpressNoInputEl"
          allowClear
          v-model="params.sellerExpressNo"
          class="w-200"
          placeholder='物流单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.orderNo"
          class="w-200 ml-5"
          placeholder='订单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.personApplyNo"
          class="w-200 ml-5"
          placeholder='D类申请评级单号'
      ></a-input>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          class="ml-10"
          @click="handleDTypeSearch"
      >搜索D类质检了但是未打印的</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          @success="getList($event)"
          @closeModal="handleCloseModal"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/checkManage/operate/_components/TableList"
import {dqCheckResultTypeList} from "@/views/cmsPage/checkManage/_data"

export default {
  components: {
    TableList,
  },
  data() {
    return {
      focusStatus: true,
      dqCheckResultTypeList: dqCheckResultTypeList,
      data: [],
      tempData: [],
      params: {
        orderNo: undefined,
        personApplyNo: undefined,
        // personApplyNo: '2023112318100001',
        sellerExpressNo: undefined,
        // sellerExpressNo: '2023112318100001',
        duibanNotVersion: undefined,
      },
    };
  },
  async mounted() {
    const { orderNo } = this.$route.query

    if (orderNo) {
      this.$set(this.params, "orderNo", orderNo)
      await this.getList()
    } else {
      this.$nextTick(()=>{
        this.$refs.sellerExpressNoInputEl.focus()
      })
    }
    // scanCodeWatch(this.handleScanCodeEvent)
  },
  methods: {
    /** 关闭弹窗之后自动聚焦到输入框 */
    handleCloseModal() {

    },
    /** 获取物流单号 */
    handleScanCodeEvent(code) {
      this.params.sellerExpressNo = code
      this.getList(code)
    },
    /** 搜索 */
    search() {
      this.params.duibanNotVersion = undefined
      this.getList();
    },
    /**  获取列表数据 */
    async getList(expressNo) {
      if (expressNo) this.params.sellerExpressNo = expressNo
      this.$loading.show()
      const res = await this.axios("/dq_admin/qualityzhandian/operateQualityQuery", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.data = res.data
    },
    /** D类搜索质检了但是未打印的数据 */
    handleDTypeSearch() {
      this.params.duibanNotVersion = 1
      this.getList()
    }
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
